import clsx from 'clsx';
import { graphql, useStaticQuery } from 'gatsby';
import React from 'react';

import { Container } from '../lib/components/layout/container';
import { Grid } from '../lib/components/layout/grid';
import { Section } from '../lib/components/layout/section';
import { SEO } from '../lib/components/seo';
import SvgIcon from '../lib/components/svg-icon/svg-icon';
import { Heading } from '../lib/components/typography/heading';
import { Paragraph } from '../lib/components/typography/paragraph';
import { BaseLayout } from '../lib/ui/base-layout';
import { GlobalPartners } from '../lib/ui/global-partners';
import { RichText } from '../lib/ui/rich-text';
import { MilestoneSlider } from '../lib/ui/slick-slider/milestone-slider';

const About = () => {
    const {
        pageContent,
        companyProfile,
        missionVision,
        milestonesText,
        milestonesJourney,
        certificationsText,
        certificationsList,
        partnerBrandsText,
        parnterBrandsCountry
    } = useStaticQuery(query);

    const content = {
        companyProfile: {
            text: companyProfile.content[0],
            images: companyProfile.images[0]
        },
        missionVision: {
            text: missionVision.content
        },
        milestones: {
            text: milestonesText,
            journey: milestonesJourney.content
        },
        certifications: {
            text: certificationsText,
            list: certificationsList.edges
        },
        partnerBrands: {
            text: partnerBrandsText,
            partners: parnterBrandsCountry.content
        }
    };

    return (
        <>
            <SEO
                title={pageContent.seo.title}
                description={pageContent.seo.description}
                image={pageContent.seo.image.file.url}
            />
            <BaseLayout>
                <Section className={clsx('bg-light-gray rounded-b-md')}>
                    <Container>
                        <Grid
                            container
                            base={false}
                            className={clsx(
                                'items-center',
                                't-lg:grid-cols-2 t-lg:gap-x-15',
                                'd-sm:grid-cols-12 d-sm:gap-x-2'
                            )}>
                            <Grid item className={clsx('d-sm:col-span-5')}>
                                <Heading tagName="h1">
                                    {content.companyProfile.text.title}
                                </Heading>
                                <RichText
                                    data={content.companyProfile.text.richText}
                                />
                            </Grid>

                            <Grid
                                item
                                className={clsx(
                                    'relative',
                                    'd-sm:col-span-8-13'
                                )}>
                                <div
                                    className={clsx(
                                        'max-w-8/10 mx-auto relative z-10',
                                        't-sm:max-w-1/2',
                                        't-lg:max-w-6/10'
                                    )}>
                                    <img
                                        src={
                                            content.companyProfile.images.file
                                                .url
                                        }
                                        alt={
                                            content.companyProfile.images
                                                .description
                                        }
                                    />
                                </div>

                                <div className="bg-light-gray-100 absolute w-full h-3/4 bottom-0 left-0 rounded-md" />
                            </Grid>
                        </Grid>
                    </Container>
                </Section>

                <Section>
                    <Container>
                        <Grid
                            container
                            base={false}
                            defaultGap={false}
                            className={clsx(
                                'gap-y-10',
                                't-sm:grid-cols-2 t-sm:gap-x-15',
                                't-lg:grid-cols-3',
                                'l-lg:gap-x-20'
                            )}>
                            <Grid item>
                                <SvgIcon
                                    icon="vision"
                                    className="h-10 min-h-7 w-auto"
                                />
                                <Heading className="mt-4">
                                    {content.missionVision.text[0].title}
                                </Heading>
                                <RichText
                                    data={
                                        content.missionVision.text[0].richText
                                    }
                                />
                            </Grid>

                            <Grid item>
                                <SvgIcon
                                    icon="mission"
                                    className="h-10 min-h-7 w-auto"
                                />
                                <Heading className="mt-4">
                                    {content.missionVision.text[1].title}
                                </Heading>
                                <RichText
                                    data={
                                        content.missionVision.text[1].richText
                                    }
                                />
                            </Grid>

                            <Grid item>
                                <SvgIcon
                                    icon="core-values"
                                    className="h-10 min-h-7 w-auto"
                                />
                                <Heading className="mt-4">
                                    {content.missionVision.text[2].title}
                                </Heading>
                                <RichText
                                    data={
                                        content.missionVision.text[2].richText
                                    }
                                />
                            </Grid>
                        </Grid>
                    </Container>
                </Section>

                <Section framed className="relative z-10 bg-primary">
                    <Container>
                        <Grid
                            container
                            base={false}
                            className={clsx(
                                'grid-cols-full',
                                't-lg:grid-cols-12 t-lg:gap-x-10 t-lg:items-center',
                                'l-lg:gap-x-2'
                            )}>
                            <Grid
                                item
                                className={clsx(
                                    'bg-primary-100 rounded-md p-8',
                                    't-lg:col-span-6',
                                    'l-lg:col-span-5'
                                )}>
                                <Heading color="white">
                                    {content.milestones.text.title}
                                </Heading>

                                <RichText
                                    color="white"
                                    data={content.milestones.text.richText}
                                />
                            </Grid>

                            <Grid
                                item
                                className={clsx(
                                    't-lg:col-span-6',
                                    'l-lg:col-span-7-13'
                                )}>
                                <MilestoneSlider
                                    milestones={content.milestones.journey}
                                />
                            </Grid>
                        </Grid>
                    </Container>
                </Section>

                <Section
                    framed
                    base={false}
                    className={clsx(
                        'rounded-t-none bg-light-gray -mt-4 pt-20 pb-16',
                        't-lg:pt-24 t-lg:pb-20'
                    )}>
                    <Container>
                        <Grid
                            container
                            base={false}
                            className={clsx(
                                't-lg:grid-cols-2 t-lg:gap-x-10',
                                'l-lg:grid-cols-12 l-lg:gap-x-2'
                            )}>
                            <Grid item className={clsx('l-lg:col-span-2-6')}>
                                <Heading>
                                    {content.certifications.text.title}
                                </Heading>

                                <RichText
                                    data={content.certifications.text.richText}
                                />
                            </Grid>

                            <Grid item className={clsx('l-lg:col-span-7-12')}>
                                <div className="bg-white px-5 py-1 rounded-md">
                                    <ul>
                                        {content.certifications.list.map(
                                            (item: {
                                                node: {
                                                    id: string;
                                                    title: string;
                                                    pdf: {
                                                        file: { url: string };
                                                    };
                                                };
                                            }) => {
                                                return (
                                                    <li
                                                        className="flex items-center justify-between py-4 border-b border-divider-light last:border-b-0"
                                                        key={item.node.id}>
                                                        <Paragraph className="pr-10">
                                                            {item.node.title}
                                                        </Paragraph>
                                                        <a
                                                            href={
                                                                item.node.pdf
                                                                    .file.url
                                                            }
                                                            target="_blank">
                                                            <SvgIcon
                                                                icon="download"
                                                                size={24}
                                                                color="#E69D17"
                                                            />
                                                        </a>
                                                    </li>
                                                );
                                            }
                                        )}
                                    </ul>
                                </div>
                            </Grid>
                        </Grid>
                    </Container>
                </Section>

                <Section>
                    <Container>
                        <Grid
                            container
                            base={false}
                            className={clsx('t-lg:grid-cols-12')}>
                            <Grid
                                item
                                className={clsx(
                                    't-lg:col-span-4',
                                    'l-lg:col-span-3'
                                )}>
                                <Heading>
                                    {content.partnerBrands.text.title}
                                </Heading>
                                <RichText
                                    data={content.partnerBrands.text.richText}
                                />
                            </Grid>

                            <Grid
                                item
                                className={clsx(
                                    't-lg:col-span-6-13',
                                    'l-lg:col-span-5-13'
                                )}>
                                <GlobalPartners
                                    data={content.partnerBrands.partners}
                                />
                            </Grid>
                        </Grid>
                    </Container>
                </Section>
            </BaseLayout>
        </>
    );
};

export default About;

const query = graphql`
    {
        pageContent: contentfulPages(slug: { eq: "/about" }) {
            seo {
                title
                description
                image {
                    file {
                        url
                    }
                }
            }
        }
        companyProfile: contentfulComponentSection(
            identifier: { eq: "about-company-profile-section" }
        ) {
            content {
                ... on ContentfulComponentText {
                    id
                    title
                    richText {
                        raw
                    }
                }
            }
            images {
                file {
                    url
                }
                description
            }
        }
        missionVision: contentfulComponentSection(
            identifier: { eq: "about-mission-vision-section" }
        ) {
            content {
                ... on ContentfulComponentText {
                    id
                    title
                    richText {
                        raw
                    }
                }
            }
        }
        milestonesText: contentfulComponentText(
            identifier: { eq: "milestones" }
        ) {
            title
            richText {
                raw
            }
        }
        milestonesJourney: contentfulMilestones {
            content {
                id
                title
                richText {
                    raw
                }
            }
        }
        certificationsText: contentfulComponentText(
            identifier: { eq: "certifications" }
        ) {
            title
            richText {
                raw
            }
        }
        certificationsList: allContentfulCertifications(
            sort: { fields: title }
        ) {
            edges {
                node {
                    id
                    title
                    pdf {
                        file {
                            url
                        }
                    }
                }
            }
        }
        partnerBrandsText: contentfulComponentText(
            identifier: { eq: "partner-brands" }
        ) {
            title
            richText {
                raw
            }
        }
        parnterBrandsCountry: contentfulComponentBlocks(
            identifier: { eq: "partners-logo-by-country-block" }
        ) {
            content {
                ... on ContentfulPartnersByCountry {
                    id
                    country
                    partners {
                        id
                        file {
                            url
                        }
                        description
                    }
                }
            }
        }
    }
`;
