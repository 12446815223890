import clsx from 'clsx';
import React, { forwardRef, useEffect, useState } from 'react';

import { Dropdown } from '../../components/dropdown';
import { Grid } from '../../components/layout/grid';
import { PartnerLogo } from '../../components/partner-logo';
import { Paragraph } from '../../components/typography/paragraph';
import { Box } from '../../components/wrapper/box';

interface GlobalPartnersProps extends React.HTMLAttributes<HTMLDivElement> {
    data: any;
}

const GlobalPartners = forwardRef<HTMLDivElement, GlobalPartnersProps>(
    ({ className, data }, ref) => {
        const [country, setCountry] = useState('Belguim');
        const [partners, setPartners] = useState<any>();

        useEffect(() => {
            const filteredPartner = data.find((item: { country: string }) => {
                return item.country === country;
            });

            setPartners(filteredPartner);
        }, [country, partners]);

        return (
            <div ref={ref}>
                <div
                    className={clsx(
                        'm-lg:flex m-lg:justify-between m-lg:items-center',
                        className
                    )}>
                    <div className="flex items-center">
                        <Paragraph className="mr-2">Country:</Paragraph>
                        <Dropdown
                            country={country}
                            setCountry={setCountry}
                            data={data}
                        />
                    </div>

                    <Paragraph className="mt-2 m-lg:mt-0">
                        Total Partners:
                        <span className="ml-1 font-medium">
                            {partners && partners.partners.length}
                        </span>
                    </Paragraph>
                </div>
                <Grid
                    container
                    base={false}
                    defaultGap={false}
                    className={clsx(
                        'grid-cols-2 gap-2 mt-4',
                        't-sm:grid-cols-3',
                        'l-lg:grid-cols-4'
                    )}>
                    {partners &&
                        partners.partners.map(
                            (item: {
                                id: string;
                                file: {
                                    url: string;
                                };
                                description: string;
                            }) => {
                                return (
                                    <Box
                                        key={item.id}
                                        ratio="1:1"
                                        className="border-divider-light border">
                                        <PartnerLogo
                                            src={item.file.url}
                                            alt={item.description}
                                        />
                                    </Box>
                                );
                            }
                        )}
                </Grid>
            </div>
        );
    }
);

export default GlobalPartners;
