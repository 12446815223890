import clsx from 'clsx';
import React, { forwardRef, useRef } from 'react';

import SvgIcon from '../svg-icon/svg-icon';
import { Paragraph } from '../typography/paragraph';
import * as style from './styles.module.css';

export interface DropdownProps extends React.HTMLAttributes<HTMLDivElement> {
    country: any;
    setCountry: any;
    data: any;
}

const handleDropClick = (el: React.RefObject<HTMLDivElement>) => {
    const dropList = el.current!;
    const dropParent = dropList.parentElement!;
    if (dropList.style.maxHeight) {
        //@ts-ignore
        dropList.style.maxHeight = null;
        dropParent.classList.remove(style.active);
    } else {
        dropList.style.maxHeight = dropList.scrollHeight + 'px';
        dropParent.classList.add(style.active);
    }
};

const handleItemClick = (
    el: EventTarget,
    dropdown: React.RefObject<HTMLDivElement>,
    setCountry: any
) => {
    const dropList = dropdown.current!;
    const dropParent = dropList.parentElement!;

    const allItem = document.querySelectorAll(`.${style.item}`);
    allItem.forEach((item) => {
        item.classList.remove(style.activeItem);
    });

    //@ts-ignore
    el.classList.add(style.activeItem);

    //@ts-ignore
    dropList.style.maxHeight = null;
    dropParent.classList.remove(style.active);

    //@ts-ignore
    setCountry(el.textContent);
};

const Dropdown = forwardRef<HTMLDivElement, DropdownProps>(
    ({ className, country, data, setCountry }, ref) => {
        const dropListRef = useRef<HTMLDivElement>(null);

        return (
            <div className="relative" ref={ref}>
                <div
                    className={clsx(
                        'px-2 py-1 w-25 min-w-20 relative',
                        'bg-light-gray rounded-sm border border-divider-light cursor-pointer',
                        className
                    )}
                    onClick={() => handleDropClick(dropListRef)}>
                    <div className="flex items-center justify-between">
                        <Paragraph className="text-opacity-100 font-medium">
                            {country}
                        </Paragraph>
                        <SvgIcon
                            icon="arrow-right-circle"
                            size={20}
                            className={style.icon}
                        />
                    </div>
                </div>
                <div
                    className={clsx(
                        'absolute w-full top-full left-0 z-10',
                        style.dropList
                    )}
                    ref={dropListRef}>
                    <ul
                        className={clsx(
                            'px-2 py-1.5 mt-1',
                            'bg-light-gray rounded-sm border border-divider-light'
                        )}>
                        {data.map((item: { id: string; country: string }) => {
                            return (
                                <li
                                    className={clsx(
                                        'flex items-center justify-between',
                                        'text-dark-gray text-opacity-80 cursor-pointer',
                                        style.item
                                    )}
                                    key={item.id}
                                    onClick={(e) => {
                                        handleItemClick(
                                            e.target,
                                            dropListRef,
                                            setCountry
                                        );
                                    }}>
                                    {item.country}
                                </li>
                            );
                        })}
                    </ul>
                </div>
            </div>
        );
    }
);

export default Dropdown;
