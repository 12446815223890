import clsx from 'clsx';
import {
    ContentfulRichTextGatsbyReference,
    RenderRichTextData
} from 'gatsby-source-contentful/rich-text';
import React, { FC, useRef } from 'react';
import Slider from 'react-slick';

import { Grid } from '../../../components/layout/grid';
import { Heading } from '../../../components/typography/heading';
import { RichText } from '../../rich-text';
import { SlickDot } from '../slick-dot';
import { SlickNav } from '../slick-nav';
import * as style from './milestone.module.css';

interface MilestoneSliderProps {
    milestones: any;
}

const MilestoneSlider: FC<MilestoneSliderProps> = ({ milestones }) => {
    const slider = useRef(null);
    const settings = {
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        appendDots: (dots: any) => <SlickNav dots={dots} slider={slider} />,
        customPaging: () => <SlickDot />,
        arrows: false
    };

    return (
        <Slider {...settings} ref={slider}>
            {milestones.map(
                (item: {
                    id: string;
                    title: string;
                    richText: RenderRichTextData<ContentfulRichTextGatsbyReference>;
                }) => {
                    return (
                        <div key={item.id}>
                            <Grid
                                container
                                base={false}
                                className={clsx('grid-cols-auto-1fr')}>
                                <div>
                                    <div className={style.circle} />
                                </div>

                                <div>
                                    <Heading color="white">
                                        {item.title}
                                    </Heading>
                                    <RichText
                                        color="white"
                                        data={item.richText}
                                        listClassName="mt-br"
                                    />
                                </div>
                            </Grid>
                        </div>
                    );
                }
            )}
        </Slider>
    );
};

export default MilestoneSlider;
